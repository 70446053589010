import React from 'react';

const Cursor = () => {
  return (
    <div data-cursor>
      <div className="cur1">
        <span className="see">View</span>
      </div>
      <div className="cur2"></div>
    </div>
  );
};

export default Cursor;
