import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import App from './App';

import './index.css';

const root = document.getElementById('root');
const container = createRoot(root);

container.render(
  <HashRouter>
    <App />
  </HashRouter>,
);

// ReactDOM.createRoot(document.getElementById('root')).render(<RouterProvider router={router} />);
