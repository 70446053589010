import React from 'react';

const Slogan = () => {
  return (
    <div className="sp sp-2">
      <div className="eavl fx" data-fx>
        <div>
          <div className="ct">
            <h2>
              <span className="text-3xl">
                우리는 특별한 <strong className="drg">DNA</strong>를
              </span>
              <span>가지고 있습니다.</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slogan;
