import React from 'react';

import config from '../config/index.json';

const MainHero = () => {
  const { MainHero } = config;

  return (
    <div className="wec">
      <div className="h1">
        <h1>{MainHero.title}</h1>
      </div>
      <div className="wem">
        <div>
          <div>{MainHero.subtitle}</div>
        </div>
        <div>
          <div>
            <div className="wew">
              {' '}
              &nbsp; <div className="active">{MainHero.description1}</div>
              <div>{MainHero.description2}</div>
              <div>{MainHero.description3}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHero;
