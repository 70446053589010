import React from 'react';

const Marquee = () => {
  return (
    <section className="text-slider-section">
      <div className="text-slider-container">
        <span className="visible">
          <span className="word">ui &amp; ux.</span>
          <span className="word">creative web.</span>
          <span className="word">web service.</span>
          <span className="word">contents service.</span>
          <span className="word">app development.</span>
          <span className="word">web publishing.</span>
        </span>
        <span className="visible reverse">
          <span className="word">IT system.</span>
          <span className="word">mobile biz.</span>
          <span className="word">web maintenance.</span>
          <span className="word">web development.</span>
          <span className="word">IT system.</span>
          <span className="word">mobile biz.</span>
          <span className="word">web maintenance.</span>
          <span className="word">web development.</span>
        </span>
      </div>
    </section>
  );
};

export default Marquee;
