import React from 'react';

const CircleEffect = () => {
  return (
    <>
      <div className="cirbc">
        <div className="ico">
          <i className="i_comm-digi n1"></i>
          <i className="i_conversion n2"></i>
          <i className="i_rs n3"></i>
        </div>
        <svg viewBox="0 0 100 100" height="125">
          <title>FRONTLAB</title>
          <defs>
            <linearGradient id="grd-av" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop data-color-c="l" offset="0%" stopColor="#27ae60" />
              <stop data-color-c="r" offset="100%" stopColor="#006d37" />
            </linearGradient>
          </defs>
          <circle stroke="url(#grd-av)" cx="50" cy="50" r="48"></circle>
        </svg>
        <div className="cirb">
          <div className="cirbr"></div>
        </div>
      </div>
    </>
  );
};

export default CircleEffect;
