import React from 'react';

const Experience = () => {
  return (
    <>
      <div id="e">
        <div className="sp sp-5"></div>
        <div className="lgtxc lgtxc-1" data-lg-st="1" data-lg-en="2">
          <div className="ct">
            <div>
              <h3>
                <strong>아이디어를 실현</strong>
              </h3>
              <div className="txt">
                <p>
                  크고 복잡한 프로젝트에 대한 경험이 있습니다. 지난 10여년 동안 디지털 플랫폼, 모바일 광고 플랫폼
                  서비스, 예약 애플리케이션, 콘텐츠 비즈니스 등을 구축했습니다.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lgtxc lgtxc-2" data-lg-st="2" data-lg-en="3">
          <div className="ct">
            <div>
              <h3>
                <strong>사용자 경험</strong>
              </h3>
              <div className="txt">
                <p>
                  맞춤형 사이트를 통해 고객에게 더 나은 경험을 제공할 수 있습니다. 모든 것을 가능하게 하는 고객 만족과
                  충성도에 영향을 미칠 수 있습니다.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lgtxc lgtxc-3" data-lg-st="3" data-lg-en="4">
          <div className="ct">
            <div>
              <h3>
                <strong>디지털 기술</strong>
              </h3>
              <div className="txt">
                <p>
                  네트워크 및 시스템 관리에서 소프트웨어 개발 및 보안에 이르는 활동과 함께 기술적인 측면을 관리하여
                  새로운 가치를 만듭니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
