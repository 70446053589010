import React from 'react';

import { Map, MapMarker, CustomOverlayMap } from 'react-kakao-maps-sdk';
import useKakaoLoader from '../useKakaoLoader';

const Maps = () => {
  useKakaoLoader();
  const defaultLevel = 3;

  return (
    <div id="map">
      <Map
        center={{ lat: 37.49200433366456, lng: 126.99063152373532 }}
        style={{
          position: 'relative',
          width: '100%',
          height: '400px',
        }}
        level={defaultLevel}
      >
        <MapMarker
          style={{ border: 'tranparent' }}
          position={{ lat: 37.49200433366456, lng: 126.99063152373532 }}
        ></MapMarker>
        <CustomOverlayMap
          position={{
            lat: 37.49200433366456,
            lng: 126.99063152373532,
          }}
        >
          <div className="customoverlay">
            <a href="https://kko.to/1DJ410EQ6E" target="_blank" title="새창 열기" rel="noreferrer">
              <span className="title">프론트랩</span>
            </a>
          </div>
        </CustomOverlayMap>
      </Map>
    </div>
  );
};

export default Maps;
