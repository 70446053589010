import React from 'react';

import config from '../config/index.json';

const About = () => {
  const { About } = config;
  const { items } = About;

  return (
    <div id="sv" data-h-color="blck-2">
      <div id="svlg" className="lg">
        {items.map((section, index) => (
          <div key={index} className="lgt fx">
            <div>
              <>
                <h3>
                  <span>{section.title}</span>
                  <strong>{section.subtitle}</strong>
                </h3>
                <div className="tx">
                  <p>{section.description1}</p>
                  <p>{section.description2}</p>
                  <ul>
                    <li key={`${section.list}-${index}`}></li>
                  </ul>
                </div>
              </>
            </div>
          </div>
        ))}
      </div>
      {/* 타원 효과 */}
      <div className="rg fx" data-fx>
        <div>
          <div className="cir">
            <div className="circ">
              <div className="circm">
                <div className="crc">
                  <div>
                    <div className="rnd">
                      <div>
                        <div>
                          <i className="i_graphisme"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="crc">
                  <div>
                    <div className="rnd">
                      <div>
                        <div>
                          <i className="i_dev"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="crc">
                  <div>
                    <div className="rnd">
                      <div>
                        <div>
                          <i className="i_marketing"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="crc">
                  <div>
                    <div className="rnd">
                      <div>
                        <div>
                          <i className="i_site"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
