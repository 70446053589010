import React, { useEffect } from 'react';
import config from '../config/index.json';
import { useLocation, useNavigate } from 'react-router-dom';

let prePath = '';
const Header = () => {
  const { company } = config;
  const { name: companyName } = company;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (prePath.indexOf('/work/') !== -1) {
      prePath = '';
      window.location.reload();
    } else if (prePath.indexOf('/contact/') !== -1) {
      prePath = '';
      window.location.reload();
    } else if (prePath.indexOf('/') !== -1) {
      prePath = '';
      window.location.reload();
    }
    prePath = location.pathname;
  }, [location]);

  return (
    <>
      <div className="l">
        <a href="/" title="홈으로 가기" className="lg" aria-label="홈으로 가기">
          <div className="logo">
            <span>{companyName}</span>
          </div>
        </a>
      </div>
      <div className="r">
        <a className="brf" href="/common/FRONTLAB_202404.pdf" title="회사소개서 다운로드">
          회사소개서 다운로드<i className="fa down"></i>
        </a>
        <div className="bn" data-nav>
          <div>
            <span></span>
            <span></span>
          </div>
          <svg viewBox="0 0 100 100">
            <title>FRONTLAB</title>
            <defs>
              <linearGradient id="grd" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop data-color-c="l" offset="0%" stopColor="#27ae60"></stop>
                <stop data-color-c="r" offset="100%" stopColor="#006d37"></stop>
              </linearGradient>
            </defs>
            <circle stroke="url(#grd)" cx="50" cy="50" r="48"></circle>
          </svg>
        </div>
      </div>
      <div id="n">
        <div className="nr">
          <div className="bg"></div>
        </div>
        <div className="nl">
          <div className="nlc">
            <nav>
              <ul>
                <li>
                  <button
                    className="link"
                    onClick={function () {
                      navigate('/');
                    }}
                  >
                    HOME
                  </button>
                </li>
                <li>
                  <button
                    className="link"
                    onClick={function () {
                      navigate('/work');
                    }}
                  >
                    WORK
                  </button>
                </li>
                <li>
                  <a href="/viewer" className="link">
                    E-PUB VIEWER
                  </a>
                </li>
                <li>
                  <a href="/gentto" className="link">
                    GENTTO
                  </a>
                </li>
                <li>
                  <button
                    className="link"
                    onClick={function () {
                      navigate('/contact');
                    }}
                  >
                    CONTACT
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          <ul class="ms">
            <li>
              <a href="/common/FRONTLAB_202404.pdf" title="회사소개서 다운로드">
                회사소개서 다운로드<i className="fa down"></i>
              </a>
            </li>
          </ul>
          <div className="bg"></div>
        </div>
      </div>
    </>
  );
};

export default Header;
