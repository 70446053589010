import React from 'react';
import { useEffect } from 'react';

const ContactPage = () => {
  useEffect(() => {
    document.getElementById('map').style.display = 'none';
  }, []);

  return (
    <>
      <header className="default">
        <div className="ct">
          <h2 className="long">
            <span className="word">
              Your business <span className="c-primary">elevated,</span>
            </span>
            <span className="word">one email away.</span>
          </h2>
        </div>
        <img data-preload src="./images/bg/contact-cover.jpg" className="fill-background" alt="gradient" />
      </header>
      <section className="text-slider-section">
        <div className="text-slider-container">
          <span className="visible">
            <span className="word">ui &amp; ux.</span>
            <span className="word">creative web.</span>
            <span className="word">web service.</span>
            <span className="word">contents service.</span>
            <span className="word">ui &amp; ux.</span>
            <span className="word">creative web.</span>
            <span className="word">web service.</span>
            <span className="word">contents service.</span>
          </span>
          <span className="visible reverse">
            <span className="word">IT system.</span>
            <span className="word">mobile biz.</span>
            <span className="word">web maintenance.</span>
            <span className="word">web development.</span>
            <span className="word">IT system.</span>
            <span className="word">mobile biz.</span>
            <span className="word">web maintenance.</span>
            <span className="word">web development.</span>
          </span>
        </div>
      </section>
      <div id="sc">
        <div className="location">
          <div className="map"></div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
