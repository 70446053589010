import React from 'react';

import config from '../config/index.json';

const WorkPopup = () => {
  const { Work } = config;
  const { id, title, client, period, device, thumbImage, items: workList } = Work; //eslint-disable-line no-unused-vars

  return (
    <>
      <div id="crea">
        {workList.map((work, index) => (
          <div key={index} className="cre" data-project={work.id}>
            <div className="r">
              <div className="rc" data-scrollbar="true" tabIndex="-1">
                <div className="rct">
                  <h6>{work.title}</h6>
                  <div className="dte">
                    <em>클라이언트</em>
                    {work.client}
                  </div>
                  <div className="dte">
                    <em>프로젝트 기간</em>
                    {work.period}
                  </div>
                  <div className="dte">
                    <em>디바이스</em>
                    {work.device}
                  </div>
                  <div className="p">
                    <p></p>
                  </div>
                </div>
                <div className="scroll-content">
                  <img src={work.thumbImage} alt="" />
                </div>
              </div>
              <div className="bg"></div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default WorkPopup;
