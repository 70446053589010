import React from 'react';

import config from '../config/index.json';

const Footer = () => {
  const { Footer } = config;
  const { utils, companyFooter } = Footer;

  return (
    <footer id="f">
      <div className="ct">
        <div className="l">
          <div className="logo">
            <span>{companyFooter}</span>
          </div>
        </div>
        {utils.map((section, index) => (
          <div className="lm" key={index}>
            <h4>{section.name}</h4>
            <div className="inf">
              <a href={section.href} target={section.target} title={section.title}>
                {section.content}
              </a>
            </div>
          </div>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
