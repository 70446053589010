import React from 'react';

import config from '../config/index.json';

const MainHeroScreen = () => {
  const { Screen } = config;
  return (
    <div className="screen">
      <div className="screen-c">
        <div className="sm">
          <div className="smc">
            <div className="smc_h"></div>
            <div className="smc_lg smc_lg1">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <ul className="smc_u">
              <li>
                <div></div>
              </li>
              <li>
                <div></div>
              </li>
              <li>
                <div></div>
              </li>
              <li>
                <div></div>
              </li>
            </ul>
            <div className="smc_s">
              <div>
                <div className="smc_s-sub"></div>
                <div className="smc_s-tit"></div>
                <div className="smc_s-tit t2"></div>
              </div>
            </div>
            <div className="smc_a"></div>
            <ul className="smc_a-c">
              <li className="fi"></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li className="fi"></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div className="smc_c1 smc_cv">
              <div></div>
              <div></div>
            </div>
            <div className="smc_c2"></div>
            <div className="smc_c3">
              <div className="fi"></div>
              <div className="fi fim"></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div className="fim"></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div className="fim fiml"></div>
              <div className="fi"></div>
              <div className="fi fim"></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div className="fim"></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div className="fim"></div>
            </div>
            <div className="smc_c4 smc_cv">
              <div></div>
              <div></div>
            </div>
            <div className="smc_ul">
              <div>
                <span>
                  <span></span>
                </span>
              </div>
              <div>
                <span>
                  <span></span>
                </span>
              </div>
              <div>
                <span>
                  <span></span>
                </span>
              </div>
            </div>
            <div className="smc_lg smc_lg2">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="smc_f">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <img src={Screen.src1} width="944" height="878" className="sc" alt="" />
        <img src={Screen.src2} width="567" height="279" className="kb" alt="" />
        <img src={Screen.src3} width="161" height="90" className="ms" alt="" />
      </div>
    </div>
  );
};

export default MainHeroScreen;
