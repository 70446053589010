import React from 'react';

import config from '../config/index.json';

const Work = () => {
  const { Work } = config;
  const { id, href, year, title, icoImage, items: workList } = Work; //eslint-disable-line no-unused-vars

  return (
    <>
      <div id="eu" data-h-color="blck-1">
        <div className="tit">
          <div>
            <div className="sub">
              <h1>{Work.top}</h1>
            </div>
            <h2 className="hspan">
              <span>
                <span>{Work.title}</span>
              </span>
            </h2>
          </div>
        </div>
        <div className="ct">
          <div className="grd" data-grid>
            {workList.map((work, index) => (
              <div key={index} className="show">
                <a href={work.href} className="menu-link" data-project-id={work.id}>
                  <div className="menu-link-box">
                    <span className="text-sm">{work.year}</span>
                    <strong className="text-lg">{work.title}</strong>
                    <img src={work.icoImage} className="full-screen-menu_link-arrow" alt="" />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Work;
