import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import config from '../config/index.json';

const WorkPage = ({ match }) => {
  const location = useLocation();
  const { Work } = config;
  const { id, top, title, thumbnail, items: workList } = Work; //eslint-disable-line no-unused-vars

  useEffect(() => {
    function handleWork() {
      document.querySelector('.grd').firstElementChild.hidden = true;
      document.getElementById('map').style.display = 'none';
    }
    handleWork();
  }, [location]);

  return (
    <>
      <div id="eu" data-h-color="blck-1">
        <section className="tit">
          <div>
            <div className="sub">
              <h1>{Work.top}</h1>
            </div>
            <h2 className="hspan">
              <span>
                <span>{Work.title}</span>
              </span>
            </h2>
            <div className="tc txc-1" aria-label="Scroll Down">
              <svg viewBox="0 0 100 100">
                <title>frontlab</title>
                <defs>
                  <path id="c-1" d="M 50, 50m -25, 0 a 25,25 0 1,1 50,0 a 25,25 0 1,1-50,0"></path>
                </defs>
                <text fontSize="8">
                  <textPath xlinkHref="#c-1">Scroll Down</textPath>
                </text>
              </svg>
              <i className="fa awd"></i>
            </div>
          </div>
        </section>
        <div className="ct service-ct">
          <div className="grd" data-grid-service="">
            {workList.map((work, index) => (
              <div key={index} className="show">
                <figure data-project-id={work.id}>
                  <img src={work.thumbnail} alt="" data-cc />
                  <figcaption>
                    <strong className="tit">{work.title}</strong>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="sp sp-3" data-h-color="blck-5"></div>
    </>
  );
};

export default WorkPage;
