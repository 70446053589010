import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import CircleEffect from './components/CircleEffect';
import Cursor from './components/Cursor';
import Footer from './components/Footer';
import Header from './components/Header';
import Maps from './components/Maps';
import Support from './components/Support';
import WorkPopup from './components/WorkPopup';

import Main from './components/Main';
import Work from './pages/Work';
import Contact from './pages/Contact';

import jQuery from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

window.$ = window.jQuery = jQuery;

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    const apps = document.createElement('script');
    apps.src = 'http://frontlab.co.kr/assets/js/apps.js';
    apps.async = true;
    document.body.appendChild(apps);

    const grid = document.createElement('script');
    grid.src = 'http://frontlab.co.kr/assets/js/grid.js';
    grid.async = true;
    document.body.appendChild(grid);
  }, []);

  return (
    <>
      <header id="h">
        <Header />
      </header>
      <div id="app">
        <div className="app-c">
          <div>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/work" element={<Work />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
          <Support />
          <Maps />
          <Footer />
        </div>
      </div>
      <button id="top" className="btn__top" onClick={scrollToTop} title="맨 위로">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M11 8.414V18h2V8.414l4.293 4.293 1.414-1.414L12 4.586l-6.707 6.707 1.414 1.414z"></path>
        </svg>
      </button>
      <WorkPopup />
      <CircleEffect />
      <Cursor />
    </>
  );
};

export default App;
