import React from 'react';

import config from '../config/index.json';

const Support = () => {
  const { Support } = config;

  return (
    <div id="co">
      <div className="ct">
        <h2>
          <strong>
            <span>{Support.title}</span>
          </strong>
          <small>{Support.subtitle}</small>
        </h2>
        <div className="bt">
          <a href={Support.url} target="_blank" title={Support.target} className="btn blck" rel="noreferrer">
            <span>{Support.description}</span>
          </a>
        </div>
        <div className="bf">
          <a href={Support.downUrl} title={Support.down} target="_blank" rel="noreferrer">
            {Support.down}
            <i className="fa down"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Support;
