import React from 'react';

import About from './About';
import Experience from './Experience';
import MainHero from './MainHero';
import MainHeroScreen from './MainHeroScreen';
import Marquee from './Marquee';
import Service from './Service';
import Slogan from './Slogan';
import Work from './Work';

const Main = () => {
  return (
    <>
      <div id="we">
        <MainHero />
        <MainHeroScreen />
      </div>
      <Service />
      <About />
      <Slogan />
      <Experience />
      <div className="sp sp-3" data-h-color="blck-5"></div>
      <Work />

      <Marquee />
    </>
  );
};

export default Main;
