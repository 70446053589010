import React from 'react';

//import config from '../config/index.json';

const Service = () => {
  //const { Service } = config;
  return (
    <div id="sc" data-h-color="blck-3">
      <div className="ct">
        <h2 className="hspan">
          <span>
            <span>Our Service</span>
          </span>
          <span>
            <span>
              <strong className="drg">Digital Consultancy</strong>.
            </span>
          </span>
        </h2>
        <h3>목적에 맞는 서비스를 제공하여 가치를 창출합니다.</h3>
        <div className="p pp">
          <p className="intro">
            디지털 비즈니스 영역에서 최고의 서비스를 제공합니다. 고객과의 <strong>신뢰</strong>를 중요시하고
            프론트랩만의 아이덴티티 전략은 현재에 이르기까지 대.내외적으로 인정받고 있습니다.
          </p>
          <p className="intro">
            비즈니스의 성공을 위한 파트너로서 웹기획 및 운영, 시스템 구축, 컨설팅 등 ICT 서비스를 제공하고 있습니다.
          </p>
        </div>
        <h3>프론트랩의 웹개발 및 서비스</h3>
        <ul className="ls lsl">
          <li>
            <div className="ic">
              <i className="i_cm"></i>
              <svg viewBox="0 0 100 100">
                <title>frontlab</title>
                <defs>
                  <linearGradient id="grd-cm" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop data-color-c="l" offset="0%" stopColor="#27ae60"></stop>
                    <stop data-color-c="r" offset="100%" stopColor="#00947e"></stop>
                  </linearGradient>
                </defs>
                <circle stroke="url(#grd-cm)" cx="50" cy="50" r="48"></circle>
              </svg>
            </div>
            <h4>
              <span>Custom Development</span>
              <strong>PWA / 모바일 앱</strong>
            </h4>
          </li>
          <li>
            <div className="ic">
              <i className="i_rs"></i>
              <svg viewBox="0 0 100 100">
                <title>frontlab</title>
                <defs>
                  <linearGradient id="grd-rs" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop data-color-c="l" offset="0%" stopColor="#27ae60"></stop>
                    <stop data-color-c="r" offset="100%" stopColor="#00947e"></stop>
                  </linearGradient>
                </defs>
                <circle stroke="url(#grd-rs)" cx="50" cy="50" r="48"></circle>
              </svg>
            </div>
            <h4>
              <span>Redesign of your website</span>
              <strong>홈페이지 운영 및 유지보수</strong>
            </h4>
          </li>
          <li>
            <div className="ic">
              <i className="i_ref-seo"></i>
              <svg viewBox="0 0 100 100">
                <title>frontlab</title>
                <defs>
                  <linearGradient id="grd-ref-seo" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop data-color-c="l" offset="0%" stopColor="#27ae60"></stop>
                    <stop data-color-c="r" offset="100%" stopColor="#00947e"></stop>
                  </linearGradient>
                </defs>
                <circle stroke="url(#grd-ref-seo)" cx="50" cy="50" r="48"></circle>
              </svg>
            </div>
            <h4>
              <span>Web Publishing</span>
              <strong>웹퍼블리싱 서비스</strong>
            </h4>
          </li>
          <li>
            <div className="ic">
              <i className="i_adwords"></i>
              <svg viewBox="0 0 100 100">
                <title>frontlab</title>
                <defs>
                  <linearGradient id="grd-cm" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop data-color-c="l" offset="0%" stopColor="#27ae60"></stop>
                    <stop data-color-c="r" offset="100%" stopColor="#00947e"></stop>
                  </linearGradient>
                </defs>
                <circle stroke="url(#grd-cm)" cx="50" cy="50" r="48"></circle>
              </svg>
            </div>
            <h4>
              <span>Online Promotion</span>
              <strong>마이크로 사이트 제작</strong>
            </h4>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Service;
